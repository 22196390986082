* {
    padding: 0;
    margin: 0;
}

@media screen and (max-width: 960px) {
    div.footer {
        width: 100%;
        height: 53vh;
        background-color: #0e7aff;
    }
    
    div.footer div.topBar {
        width: 100%;
        height: 5vh;
        background-color: #338dfc;
    }

    div.footer img {
        position: absolute;
        right: 0vh;
        bottom: 0vh;
        height: 20vh;
    }

    div.footer div.links {
        position: relative;
        width: 50%;
        left: 20%;
        top: 7vh;
    }

    div.footer div.links p {
        color: #fff;
        font-size: 2.5vh;
        font-weight: 500;
        margin-bottom: 1vh;
    }

    div.footer div.links div.navigation ul {
        line-height: 3vh;
        margin-left: 5%;
        margin-bottom: 5vh;
    }

    div.footer div.links div.navigation ul li {
        list-style: none;
        font-size: 2.5vh;
    }

    div.footer div.links div.navigation ul li a {
        color: #fff;
        text-decoration: none;
    }

    div.footer div.links div.rights ul {
        line-height: 3vh;
        margin-left: 5%;
        margin-bottom: 5vh;
    }

    div.footer div.links div.rights ul li {
        list-style: none;
        font-size: 2.5vh;
    }

    div.footer div.links div.rights ul li a {
        color: #fff;
        text-decoration: none;
    }
}

@media screen and (min-width: 960px) {
    div.footer {
        width: 100%;
        height: 30vh;
        background-color: #0e7aff;
    }
    
    div.footer div.topBar {
        width: 100%;
        height: 5vh;
        background-color: #338dfc;
    }

    div.footer img {
        position: absolute;
        right: 0vh;
        bottom: 0vh;
        height: 20vh;
    }

    div.footer div.links {
        position: relative;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        width: 50%;
        left: 15%;
        top: 7vh;
    }

    div.footer div.links p {
        color: #fff;
    }

    div.footer div.links div.navigation ul {
        line-height: 2.5vh;
    }

    div.footer div.links div.navigation ul li {
        list-style: none;
    }

    div.footer div.links div.navigation ul li a {
        color: #fff;
        text-decoration: none;
    }

    div.footer div.links div.rights ul {
        line-height: 2.5vh;
    }

    div.footer div.links div.rights ul li {
        list-style: none;
    }

    div.footer div.links div.rights ul li a {
        color: #fff;
        text-decoration: none;
    }
}