* {
    padding: 0;
    margin: 0;
}

html {
    scroll-behavior: smooth;
    overflow-x: hidden;
    max-width: 100vw;
}

@media screen and (max-width: 960px) {
    div.home {
        width: 100%;
    }

    div.home div.footer {
        position: relative;
        top: 0;
    }

    div.home #mobileTitle {
        position: absolute;
        z-index: 10;
        font-size: 2.5vh;
        width: 50%;
        left: 25%;
        font-weight: 500;
        color: #fff;
        text-align: center;
        top: 4vh;
        transform: translateY(-50%);
    }

    div.home div.firstSite {
        height: 87vh;
        width: 100%;
        position: relative;
        top: -2vh;
        background-color: #0e7aff;
    }

    div.home div.firstSite div.topPart div.text {
        position: relative;
        left: 10%;
        top: 10vh;
        width: 80%;
    }

    div.home div.firstSite div.topPart div.text h1 {
        color: #fff;
        font-size: 4.5vh;
        font-weight: 400;
        margin-top: 10vh;
    }

    div.home div.firstSite div.topPart div.text p {
        color: #fff;
        width: 100%;
        font-size: 2.5vh;
        font-weight: 400;
        margin-top: 18vh;
        text-align: left;
    }

    div.home div.firstSite div.topPart div.backgroundIphone img {
        width: 0;
        visibility: hidden;
    }

    div.home div.firstSite #scrollDownButton {
        position: absolute;
        left: 10%;
        top: 30vh;
        height: 7vh;
        z-index: 4;
    }

    div.home div.firstSite div.whiteBorderLine {
        width: 0;
        visibility: hidden;
    }

    div.home div.firstSite div.whiteBorderLine img {
        width: 0;
    }

    div.home div.secondSite {
        position: relative;
        width: 100%;
        height: 70vh;
        top: -2vh;
        background-color: #0e7aff;
    }

    div.home div.secondSite div.iphones {
        position: relative;
        top: 5vh;
        margin: 0;
        left: 13%;
        width: 60%;
        display: grid;
        z-index: 3;
        grid-template-columns: 1fr 1fr 1fr;
    }

    div.home div.secondSite div.iphones #firstIphone {
        margin-top: 8vh;
    }

    div.home div.secondSite div.iphones #secondIphone {
        z-index: 3;
    }

    div.home div.secondSite div.iphones #thirdIphone {
        margin-top: 8vh;
    }

    div.home div.secondSite div.iphones img {
        width: 180%;
    }

    div.home div.secondSite div.availableIcons {
        position: absolute;
        top: -57vh;
        left: 10%;
        width: 80%;
        display: grid;
        grid-template-rows: 1fr 1fr;
    }

    div.home div.secondSite div.availableIcons div.appstore {
        position: absolute;
        left: 30%;
        top: 0;
    }

    div.home div.secondSite div.availableIcons div.appstore img {
        height: 7vh;
    }

    div.home div.secondSite div.availableIcons div.socialMediaLinks {
        position: relative;
        top: 45vh;
        z-index: 7;
        height: 7.5vh;
        left: calc(20% - 0.25vh);
        width: 60%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        background-color: #ffffffb2;
        border-radius: 10px;
        padding-left: 0.5vh;
    }

    div.home div.secondSite div.availableIcons div.socialMediaLinks a {
        display: block;
        padding: 2vh;
    }

    div.home div.secondSite div.availableIcons div.socialMediaLinks img {
        height: 3.5vh;
    }

    div.home div.secondSite div.circles {
        visibility: hidden;
        height: 0;
    }

    div.home div.secondSite div.circles #circle1 {
        width: 0;
    }

    div.home div.secondSite div.circles #circle2 {
        width: 0;
    }

    div.home div.thirdSite {
        position: relative;
        z-index: 3;
        width: 100%;
        height: 110vh;
        padding-bottom: 28vh;
        padding-top: 5vh;
        top: 0vh;
        margin: 0;
        background-color: #fff;
    }

    div.home div.thirdSite h1 {
        position: absolute;
        z-index: 1;
        color: #111;
        font-size: 3.5vh;
        font-weight: 700;
        width: 100%;
        text-align: center;
        top: 5vh;
    }

    div.home div.thirdSite h1::before {
        position: absolute;
        content: '';
        height: 3px;
        width: 10%;
        background-color: #111;
        bottom: -5px;
        left: 45%;
    }

    div.home div.thirdSite div.blueBorderLine {
        width: 0;
        visibility: hidden;
    }

    div.home div.thirdSite div.blueBorderLine img {
        width: 0;
    }

    div.home div.thirdSite div.aboutPart {
        position: relative;
        top: 10vh;
    }

    div.home div.thirdSite div.aboutPart img  {
        width: 70%;
        position: absolute;
        left: 15%;
    }

    div.home div.thirdSite div.aboutPart div.profileText  {
        position: relative;
        left: 10%;
        top: 27vh;
        width: 80%;
    }

    div.home div.thirdSite div.aboutPart div.profileText h1  {
        text-align: center;
        color: #111;
        font-weight: 500;
        font-size: 2.5vh;
        margin-left: 0%;
    }

    div.home div.thirdSite div.aboutPart div.profileText h1::before  {
        visibility: hidden;
    }

    div.home div.thirdSite div.aboutPart div.profileText p  {
        text-align: center;
        position: absolute;
        color: #111;
        font-weight: 500;
        font-size: 2.5vh;
        margin-top: 17vh;
    }

    div.home div.fourthSite {
        width: 100%;
        height: 140vh;
        background-color: #0e7aff;
        position: relative;
        top: 5vh;
        padding-top: 3vh;
        z-index: 2;
    }

    div.home div.fourthSite h1 {
        position: relative;
        z-index: 1;
        color: #fff;
        font-size: 3.5vh;
        font-weight: 700;
        text-align: center;
        margin-top: 5vh;
    }

    div.home div.fourthSite h1::before {
        position: absolute;
        content: '';
        height: 3px;
        width: 10%;
        background-color: #fff;
        bottom: -5px;
        left: 45%;
    }

    div.home div.fourthSite div.whiteBorderLine {
        width: 0;
        visibility: hidden;
    }

    div.home div.fourthSite div.whiteBorderLine img {
        width: 0;
    }

    div.home div.fourthSite div.gameplayIntroduction {
        margin-top: -4vh;
        width: 80%;
        margin-left: 10%;
    }

    div.home div.fourthSite div.gameplayIntroduction div.text {
        position: relative;
        z-index: 2;
        top: 0;
        width: 100%;
    }

    div.home div.fourthSite div.gameplayIntroduction div.text h1 {
        text-align: center;
        color: #fff;
        font-weight: 300;
        font-size: 3vh;
        margin-top: 8vh;
    }

    div.home div.fourthSite div.gameplayIntroduction div.text h1::before {
        visibility: hidden;
    }

    div.home div.fourthSite div.gameplayIntroduction div.text p  {
        text-align: center;
        color: #fff;
        font-weight: 400;
        font-size: 2.5vh;
        margin-top: 38vh;
    }

    div.home div.fourthSite div.gameplayIntroduction img  {
        position: absolute;
        z-index: 2;
        left: 30%;
        width: 40%;
        top: 28vh;
    }

    div.home div.fifthSite {
        width: 100%;
        height: 85vh;
        position: relative;
        top: 15vh;
    }

    div.home div.fifthSite div.download img {
        position: relative;
        z-index: 3;
        width: 40%;
        left: 10%;
    }

    div.home div.fifthSite div.download div.downloadInformation {
        position: relative;
        top: -15vh;
        z-index: 3;
        width: 80%;
        left: 10%;
    }

    div.home div.fifthSite div.download div.downloadInformation h1 {
        position: absolute;
        font-size: 3.7vh;
        font-weight: 700;
        text-align: left;
        top: -25vh;
        left: 65%;
    }

    div.home div.fifthSite div.download div.downloadInformation p  {
        text-align: left;
        width: 100%;
        font-weight: 500;
        font-size: 2.5vh;
        margin-top: 20vh;
    }

    div.home div.fifthSite div.download div.downloadInformation #appstoreIcon  {
        position: absolute;
        width: 50%;
        left: 25%;
        top: 23vh;
    }
}

@media screen and (min-width: 960px) {
    div.home {
        width: 100%;
    }

    div.home div.footer {
        position: absolute;
        top: 405vh;
    }

    div.home #mobileTitle {
        visibility: hidden;
        height: 0;
    }

    div.home div.firstSite {
        height: 93vh;
        width: 100%;
        position: absolute;
        top: 7vh;
        background-color: #0e7aff;
    }

    div.home div.firstSite div.topPart {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    div.home div.firstSite div.topPart div.text {
        position: relative;
        left: 25%;
        top: 10vh;
        width: 70%;
    }

    div.home div.firstSite div.topPart div.text h1 {
        color: #fff;
        font-size: 5vh;
        font-weight: 200;
        margin-top: 7vh;
    }

    div.home div.firstSite div.topPart div.text p {
        color: #fff;
        font-size: 2vh;
        font-weight: 300;
        margin-top: 5vh;
    }

    div.home div.firstSite div.topPart div.backgroundIphone {
        position: absolute;
        right: 10%;
        top: 10vh;
        width: 45vh;
    }

    div.home div.firstSite div.topPart div.backgroundIphone img {
        width: 100%;
    }

    div.home div.firstSite #scrollDownButton {
        position: relative;
        width: 4%;
        margin-top: 20vh;
        margin-left: 13%;
    }

    div.home div.firstSite div.whiteBorderLine {
        position: absolute;
        width: 100%;
        bottom: -20vh;
        right: 0;
    }

    div.home div.firstSite div.whiteBorderLine img {
        width: 100%;
    }

    div.home div.secondSite {
        position: absolute;
        width: 100%;
        height: 60vh;
        top: 100vh;
        z-index: 0;
    }

    div.home div.secondSite div.iphones {
        position: relative;
        top: -20vh;
        left: 5%;
        width: 50%;
        display: grid;
        z-index: 3;
        grid-template-columns: 1fr 1fr 1fr;
    }

    div.home div.secondSite div.iphones #firstIphone {
        margin-top: 15vh;
    }

    div.home div.secondSite div.iphones #secondIphone {
        margin-top: 7.5vh;
    }

    div.home div.secondSite div.iphones img {
        width: 80%;
    }

    div.home div.secondSite div.availableIcons {
        position: absolute;
        top: 0;
        right: 0;
        width: 50%;
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    div.home div.secondSite div.availableIcons div.appstore {
        position: relative;
        left: 50%;
        top: 5vh;
    }

    div.home div.secondSite div.availableIcons div.appstore img {
        width: 40%;
    }

    div.home div.secondSite div.availableIcons div.socialMediaLinks {
        position: relative;
        width: 10%;
        left: 70%;
        display: grid;
        grid-template-rows: 1.1fr 1fr 1fr;
    }

    div.home div.secondSite div.availableIcons div.socialMediaLinks img {
        width: 100%;
        margin: 30%;
    }

    div.home div.secondSite div.circles {
        position: absolute;
        top: 30vh;
        width: 100%;
        height: 100vh;
    }

    div.home div.secondSite div.circles #circle1 {
        position: relative;
        z-index: 0;
        top: -8vh;
        left: -10%;
        width: 35%;
    }

    div.home div.secondSite div.circles #circle2 {
        position: absolute;
        margin: 0;
        z-index: 1;
        top: -5vh;
        right: 0;
        width: 20%;
    }

    div.home div.thirdSite {
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100vh;
        top: 150vh;
        background-color: #0e7aff;
    }

    div.home div.thirdSite h1 {
        position: relative;
        z-index: 1;
        color: #111;
        font-size: 5vh;
        font-weight: 700;
        text-align: center;
        margin-top: 0vh;
    }

    div.home div.thirdSite div.mobileAboutBackground {
        height: 0;
        visibility: hidden;
    }

    div.home div.thirdSite div.mobileAboutBackground img {
        height: 0;
        visibility: hidden;
    }

    div.home div.thirdSite div.blueBorderLine {
        position: relative;
        width: 100%;
        top: -18vh;
    }

    div.home div.thirdSite div.blueBorderLine img {
        width: 100%;
        margin-top: 10vh;
    }

    div.home div.thirdSite div.aboutPart {
        position: relative;
        top: -20vh;
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    div.home div.thirdSite div.aboutPart img  {
        width: 80%;
        margin-left: 10%;
    }

    div.home div.thirdSite div.aboutPart div.profileText  {
        position: relative;
        left: 10%;
        top: -5vh;
        width: 65%;
    }

    div.home div.thirdSite div.aboutPart div.profileText h1  {
        text-align: left;
        color: #fff;
        font-weight: 400;
        font-size: 4vh;
        margin-bottom: 5vh;
    }

    div.home div.thirdSite div.aboutPart div.profileText p  {
        text-align: left;
        color: #fff;
        font-weight: 400;
        font-size: 2vh;
        margin-bottom: 5vh;
    }
    
    div.home div.fourthSite {
        width: 100%;
        height: 90vh;
        background-color: #0e7aff;
        position: absolute;
        top: 240vh;
    }

    div.home div.fourthSite h1 {
        position: relative;
        z-index: 1;
        color: #fff;
        font-size: 5vh;
        font-weight: 700;
        text-align: center;
        margin-top: 5vh;
    }

    div.home div.fourthSite div.whiteBorderLine {
        position: absolute;
        width: 100%;
        z-index: 1;
        bottom: -5vh;
    }

    div.home div.fourthSite div.whiteBorderLine img {
        width: 100%;
    }

    div.home div.fourthSite div.gameplayIntroduction {
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin-top: 10vh;
        width: 90%;
        margin-left: 5%;
    }

    div.home div.fourthSite div.gameplayIntroduction div.text {
        position: relative;
        z-index: 2;
        width: 95%;
        margin-left: 15%;
    }

    div.home div.fourthSite div.gameplayIntroduction div.text h1 {
        text-align: left;
        color: #fff;
        font-weight: 400;
        font-size: 4vh;
        margin-bottom: 5vh;
    }

    div.home div.fourthSite div.gameplayIntroduction div.text p  {
        text-align: left;
        color: #fff;
        font-weight: 400;
        font-size: 2vh;
        margin-bottom: 5vh;
    }

    div.home div.fourthSite div.gameplayIntroduction img  {
        position: relative;
        z-index: 2;
        left: 30%;
        width: 55%;
        top: 3vh;
    }

    div.home div.fifthSite {
        width: 100%;
        height: 45vh;
        position: absolute;
        top: 360vh;
    }

    div.home div.fifthSite div.download {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    div.home div.fifthSite div.download img {
        position: relative;
        z-index: 4;
        width: 50%;
        left: 20%;
        top: -20vh;
    }

    div.home div.fifthSite div.download div.downloadInformation {
        position: relative;
        top: -15vh;
        z-index: 3;
        width: 70%;
    }

    div.home div.fifthSite div.download div.downloadInformation h1 {
        position: relative;
        font-size: 5vh;
        font-weight: 700;
        text-align: left;
        margin-top: 0vh;
    }

    div.home div.fifthSite div.download div.downloadInformation p  {
        text-align: left;
        font-weight: 400;
        font-size: 2vh;
        margin-top: 5vh;
    }

    div.home div.fifthSite div.download div.downloadInformation #appstoreIcon  {
        position: relative;
        width: 30%;
        left: 10%;
        top: 7vh;
    }
}