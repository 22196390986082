* {
    margin: 0;
    padding: 0;
}

@media screen and (max-width: 960px) {
    div.development {
        width: 100%;
    }

    div.development div.footer {
        position: relative;
        top: 0;
    }

    div.development div.firstSite {
        position: relative;
        top: 0;
        height: 100vh;
        width: 100%;
        background-color: #fff;
    }

    div.development div.firstSite h1 {
        padding-top: 15vh;
        margin-left: 15%;
        color: #fff;
        font-size: 5vh;
        font-weight: 200;
    }
}

@media screen and (min-width: 960px) {
    div.development {
        width: 100%;
    }

    div.development div.footer {
        position: relative;
        top: 0;
    }

    div.development div.firstSite {
        position: relative;
        top: 0;
        height: 100vh;
        width: 100%;
        background-color: #fff;
    }

    div.development div.firstSite h1 {
        padding-top: 15vh;
        margin-left: 15%;
        color: #fff;
        font-size: 5vh;
        font-weight: 200;
    }
}