* {
    padding: 0;
    margin: 0;
}

@media screen and (max-width: 960px) {
    nav {
        position: fixed;
        top: 0;
        width: 100%;
        height: 8vh;
        background-color: #0e7aff;
        z-index: 8;
    }

    nav h2 {
        visibility: hidden;
    }

    nav img.icon-navbar {
        height: 23vh;
        left: 0;
        top: 0;
        z-index: 10;
        position: fixed;
    }

    nav div.navigation {
        position: fixed;
        top: 8vh;
        right: 0;
        width: 100%;
    }

    nav div.navigation-links {
        left: 0;
        width: 100%;
        height: 100vh;
        top: 0;
        background-color: #0e7aff;
        position: absolute;
        transition: 0.3s ease-out;
        visibility: hidden;
        opacity: 0;
    }

    nav div.navigation-links.active {
        right: 0;
        top: 0;
        visibility: visible;
        opacity: 1;
    }

    nav div.navigation-links ul {
        list-style: none;
        margin-top: 20vh;
        margin-left: 10%;
        width: 80%;
        text-align: center;
     }

    nav div.navigation-links ul li {
        margin: 20%;
        margin-bottom: 20%;
        font-weight: 600;
        font-size: 2.6vh;
     }

    nav div.navigation-links ul li a {
       color: #fff;
       text-decoration: none;
    }

    nav div.mobile-navigation-icon {
        z-index: 10;
        position: absolute;
        right: 3%;
        top: 0;
        margin: 0;
        height: 8vh;
        width: 8vh;
        background-color: #0e7aff;
    }

    nav div.mobile-navigation-icon a.icon {
        width: 25px;
        display: grid;
        grid-template-rows: 1fr 1fr 1fr;
        grid-row-gap: 4px;
        margin: 50% 50%;
        transform: translate(-50%, -50%);
      }

      nav div.mobile-navigation-icon a.icon i {
        height: 2px;
        width: 100%;
        background-color: white;
      }
}

@media screen and (min-width: 960px) {
    nav {
        top: 0;
        width: 100%;
        height: 7vh;
        background-color: #0e7aff;
    }

    nav img.icon-navbar {
        position: relative;
        height: 22vh;
        left: 0;
        top: 0;
        z-index: 8;
    }

    nav h2 {
        position: absolute;
        left: 20%;
        top: 0;
        margin-top: 3.5vh;
        transform: translateY(-50%);
        color: #fff;
    }

    nav div.navigation {
        position: absolute;
        top: 0;
        width: 30%;
        right: 5%;
        z-index: 8;
    }

    nav div.navigation-links {
        height: 7vh;
        z-index: 1;
        transition: 0.5s ease-out;
        box-shadow: 0 0 0 0;
    }
    
    nav div.navigation ul {
        list-style: none;
        margin-top: 0;
        height: 7vh;
        padding-top: 30px;
    }
    
    nav div.navigation ul li {
        display: inline;
        margin: 10%;
    }
    
    nav div.navigation ul li a {
        text-decoration: none;
        color: #F2F2F2;
        font-weight: bold;
    }

    nav div.navigation-links ul li:hover a:before {
        position: absolute;
        bottom: 0;
        content: '';
        height: 2px;
        width: auto;
        background-color: #000;
     }

    nav div.mobile-navigation-icon {
        margin-left: -100%;
    }
}