* {
    margin: 0;
    padding: 0;
}

@media screen and (max-width: 960px) {
    div.contact {
        width: 100%;
    }

    div.contact div.footer {
        position: relative;
        top: 0;
    }

    div.contact div.firstSite {
        position: relative;
        top: 0;
        height: 92vh;
        width: 100%;
        background-color: #0e7aff;
    }

    div.contact div.firstSite h1 {
        position: relative;
        top: 15vh;
        left: 15%;
        width: 50%;
        color: #fff;
        font-size: 5vh;
        font-weight: 400;
    }

    div.contact div.firstSite img.contactBackground {
        height: 17vh;
        width: 60%;
        position: relative;
        left: 20%;
        top: 22vh;
        margin: 0;
    }

    div.contact div.firstSite div.text {
        position: relative;
        width: 70%;
        left: 15%;
        top: 28vh;
        color: #fff;
        font-weight: 500;
        line-height: 130%;
        z-index: 5;
    }

    div.contact div.firstSite div.text p {
        font-size: 2.5vh;
    }

    div.contact div.firstSite div.text a {
        color: #111;
        text-decoration: none;
    }

    div.contact div.firstSite div.text div.mailContainer {
        position: relative;
        width: 80%;
        padding: 1vh 2vh;
        background-color: #ffffffb2;
        border-radius: 7px;
        margin-top: 2vh;
        margin-bottom: 5vh;
    }

    div.contact div.firstSite div.text div.mailContainer p {
        color: #111;
    }
}

@media screen and (min-width: 960px) {
    div.contact {
        width: 100%;
    }

    div.contact div.footer {
        position: relative;
        top: 0;
    }

    div.contact div.firstSite {
        position: relative;
        top: 0;
        height: 93vh;
        width: 100%;
        background-color: #0e7aff;
        z-index: 6;
    }

    div.contact div.firstSite h1 {
        position: relative;
        top: 15vh;
        left: 15%;
        color: #fff;
        font-size: 5vh;
        font-weight: 400;
        width: 50%;
    }

    div.contact div.firstSite img.contactBackground {
        height: 24vh;
        position: absolute;
        right: 15%;
        top: 45vh;
    }

    div.contact div.firstSite div.text {
        position: relative;
        width: 70%;
        left: 15%;
        top: 25vh;
        color: #fff;
        font-weight: 500;
        line-height: 130%;
        z-index: 5;
    }

    div.contact div.firstSite div.text p {
        font-size: 2.5vh;
    }

    div.contact div.firstSite div.text a {
        color: #111;
        text-decoration: none;
    }

    div.contact div.firstSite div.text div.mailContainer {
        position: relative;
        /* height: 2.7vh; */
        width: 35%;
        min-width: 300px;
        padding: 1vh 2vh;
        background-color: #ffffffb2;
        border-radius: 7px;
        margin-top: 2vh;
        margin-bottom: 5vh;
    }

    div.contact div.firstSite div.text div.mailContainer p {
        color: #111;
    }
}